#pdfView .pdfViewer__page-container {
  height: 100% !important;
  width: 100% !important;
  border: unset;
  margin: unset;
}

#pdfView .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

#pdfView .react-pdf__Page__textContent {
  width: 100% !important;
}

#react-doc-viewer {
  border-radius: 0.625rem !important;
}

#pdf-controls {
  border-bottom: 2px solid #f3f3f4;
  box-shadow: unset;
  justify-content: center;
  padding: 12px;
}

.w-mx-content {
  width: max-content;
}

.resize-none {
  resize: none;
}

.filter-popup {
  transform: "translate3d(-294px, 186.5px, 0px)";
}

/* For Chrome, Safari, Edge, Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.no-arrows {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 900px) {
  .rdrDefinedRangesWrapper {
    display: none;
  }
}

.input-attach-files::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #009ef7;
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
  border-radius: 0.475rem;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.input-attach-files::file-selector-button:hover {
  background: #26adf8;
}

.cursor-default-imp {
  cursor: auto !important;
}

.doc__modal #image-img {
  max-width: 100%;
  max-height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100vw;
    width: 100%;
    padding: 0 20px;
  }
}

.z-i {
  z-index: 45;
}

.z-ix {
  z-index: 50;
}

.fixed_column {
  position: sticky;
  z-index: 10;
}

.sidebar_wrapper .header-brand {
  border-bottom: 1px solid #2d2d43;
}

.desktop-logo {
  width: 80%;
}

.aside-minimize {
  left: 18%;
}

.aside-user .symbol {
  display: flex;
  justify-content: center;
}

.body_wrapper #kt_header {
  width: calc(100% - 265px) !important;
  left: 265px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media only screen and (max-width: 992px) {
  .body_wrapper #kt_header {
    width: 100% !important;
  }
}

/* Sidebar Minimize */
@media (min-width: 992px) {
  [data-kt-aside-minimize="on"] .body_wrapper {
    transition: padding-left 0.3s ease;
    padding-left: 75px;
  }

  .sidebar_wrapper {
    top: 0;
    left: 0 !important;
  }

  .sidebar_wrapper .header-brand .aside-minimize .minimize-active,
  .sidebar_wrapper .header-brand .aside-minimize.active .minimize-default {
    display: none;
  }

  .sidebar_wrapper .header-brand .aside-minimize.active .minimize-active {
    display: inline-flex;
  }
}

[data-kt-aside-minimize="on"] .body_wrapper .header-brand {
  width: 75px;
}

[data-kt-aside-minimize="on"] .body_wrapper .aside-minimize {
  left: 6%;
}

[data-kt-aside-minimize="on"] .body_wrapper #kt_header {
  width: calc(100% - 75px) !important;
  left: 75px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

[data-kt-aside-minimize="on"] .body_wrapper .header-brand .desktop-minimize-logo {
  display: flex !important;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper {
  width: 75px;
  transition: width 120ms;
  transition-delay: 120ms;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper .aside-user {
  height: 88.9333px;
  width: 100%;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper .aside-menu .menu-title,
[data-kt-aside-minimize="on"] .sidebar_wrapper .aside-menu .menu-arrow,
[data-kt-aside-minimize="on"] .body_wrapper .header-brand .desktop-logo {
  display: none;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper .aside-user-info,
[data-kt-aside-minimize="on"] .sidebar_wrapper .aside-menu .menu-sub-accordion.show,
[data-kt-aside-minimize="on"] .sidebar_wrapper .aside-menu .menu-sub-accordion {
  visibility: hidden;
  margin-left: 0 !important;
}

/* Sidebar Minimize Hover */
[data-kt-aside-minimize="on"] .sidebar_wrapper:hover + .body_wrapper .header-brand {
  width: 265px;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper:hover + .body_wrapper .aside-minimize {
  left: 24%;
}

[data-kt-aside-minimize="on"]
  .sidebar_wrapper:hover
  + .body_wrapper
  .header-brand
  .desktop-minimize-logo {
  display: none !important;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper:hover {
  width: 265px;
  transition: width 120ms;
  transition-delay: 120ms;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper:hover .aside-menu .menu-title,
[data-kt-aside-minimize="on"] .sidebar_wrapper:hover + .body_wrapper .header-brand .desktop-logo {
  display: flex;
  transition-delay: 150ms;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper:hover .aside-menu .menu-arrow {
  display: flex;
  transition-delay: 150ms;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper:hover .aside-user-info,
[data-kt-aside-minimize="on"] .sidebar_wrapper .aside-menu .menu-sub-accordion.show,
[data-kt-aside-minimize="on"] .sidebar_wrapper:hover .aside-menu .menu-sub-accordion {
  visibility: visible;
  margin-left: 1.25rem !important;
  transition-delay: 150ms;
}

[data-kt-aside-minimize="on"] .sidebar_wrapper:hover .aside-user {
  height: 100%;
  transition-delay: 150ms;
}

@media (max-width: 1440px) {
  .planned_orders--table,
  .projected_stock--table {
    max-height: 52vh !important;
  }
}

.planned_orders--table,
.projected_stock--table {
  max-height: 62vh;
}

#ordlnk_table_projectedstocks thead {
  background-color: var(--bs-card-bg);
  position: sticky;
  top: 0;
  z-index: 50;
}

.table-row-bordered-custom tr {
  border-width: 1px 0 1px 1px !important;
  border-style: solid !important;
  border-color: var(--bs-gray-400) !important;
}

#ordlnk_table_projectedstocks thead tr {
  border: unset !important;
}

#ordlnk_table_projectedstocks tbody tr:first-child {
  border-top: 1px solid var(--bs-gray-900) !important;
}

#ordlnk_table_projectedstocks tbody tr:last-child {
  border-bottom: 1px solid var(--bs-gray-900) !important;
}

#ordlnk_table_projectedstocks tfoot tr {
  border: 1px solid var(--bs-gray-900) !important;
}

.brd-custom {
  border-right-color: var(--bs-gray-500) !important;
}

.manual-border {
  border-left: 1px solid var(--bs-gray-900) !important;
}

@media (max-width: 1440px) {
  .table-mh-custom {
    max-height: 52vh !important;
  }
}

.table-mh-custom {
  max-height: 62vh;
}

.table-custom td,
.table-custom th {
  padding: 9px !important;
}

#doc-nav {
  width: 100%;
}

#doc-nav-info {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.text-primary-color {
  color: #009ef7;
}

.order-detail .card-header {
  padding: 0px !important;
  min-height: auto;
}

.order-detail .card-header .card-toolbar {
  margin: 0px !important;
}

.sorting-active {
  color: var(--bs-text-primary) !important;
}

#modal-height {
  max-height: 70vh;
}
