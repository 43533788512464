.topBar {
  display: flex;
  justify-content: space-between;
  /* flex: 1; */
  align-items: center;
  height: 72px;
  border-bottom: 1px solid var(--Color-Tokens-Border-Primary, #e4e4e4);
  background: var(--CLOUD, #fcfdfe);
  padding: 24px;

  > h1 {
    color: #0e0e2c;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .rightBar {
    display: flex;
    align-items: center;
    gap: 24px;

    > .item {
      cursor: pointer;
    }

    .activeProfile {
      color: #1249bf !important;
    }

    > .profile {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0px 8px;
      cursor: pointer;
      position: relative;

      /* Body/Base/Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      color: var(--Color-Tokens-Content-Dark-Primary, #000);
      text-align: center;
      border-radius: 8px;
      background: var(--Mist, #f7f8f9);

      > .nameIcon {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: normal;
        color: #fcfdfe;
        border-radius: 24px;
        background: #22307a;
      }

      .profileModal {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 458px;
        height: 446px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(--SILVER, #dadff3);
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 16px;

        > .profileIcon {
          display: flex;
          width: 120px;
          height: 120px;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 100px;
          background: var(--DARK-BLUE, #1249bf);
          color: #fcfdfe;
          font-family: Inter;
          font-size: 64px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          position: absolute;
          top: 131px;
        }
        > .modalDetails {
          margin-top: 56px;

          h3 {
            color: #272727;
            text-align: center;
            font-family: "Nunito Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          h5 {
            margin: 4px 0 16px;
            color: #272727;
            text-align: center;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          h6 {
            color: #272727;
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 16px;
          }

          .setting {
            color: var(--BLUE, #2060ea);
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;

            span {
              padding-left: 8px;
            }
          }
        }
      }
    }
  }
}
